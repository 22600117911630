import Style from './SliderPod.module.scss';
import utils from 'styles/globals/utils.module.scss';
import Link from 'next/link';
import Image from "next/image";
import ProductPrice from '../../ProductPrice';
import clsx from 'clsx';
import { blurImage } from 'lib/Constants/blurImage';
import stripBrandFromName from 'lib/Utils/stripBrandFromName';


function SliderPod({ 
    name = "", 
    image_url = "", 
    brand = "",  
    price, 
    url = "", 
    linkParams = "",
    sale_flag,
    msrp,
    isLoaded = false,
    className = "keen-slider__slide",
    children = null
}){

    return (
        <div className={clsx(Style.block, Style.recommBlock, className)}>
             <figure className={clsx(Style.cntr)}>
                <Link
                    href={(url.split(".com")[1] ?? url) + (linkParams && `?${linkParams}`)}
                    className={Style.imgLink}
                >
                    <div className={Style.imgPrimary}>
                        <Image
                            width={300}
                            height={300}
                            alt={name}
                            placeholder="blur"
                            blurDataURL={blurImage}
                            className={Style.img}
                            src={isLoaded ? image_url : blurImage}
                        />
                    </div>
                </Link>
            </figure>


            <div className={Style.body}>
                <Link
                    href={(url.split(".com")[1] ?? url) + (linkParams && `?${linkParams}`)}
                    aria-label={name}
                    className={Style.linkBlock}
                >
                    <div className={Style.titleBlock}>
                        {name && (
                            <>
                                <h3 className={Style.titleLink}>
                                    {brand ? stripBrandFromName(brand, name) : name}
                                </h3>

                                {brand && (
                                    <p className={Style.brandLInk}>
                                        by {brand}
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                   
                   {price && (
                        <div className={utils.mt_1}>
                            <ProductPrice
                                className={Style.price}
                                rrp={(sale_flag !== 'No' && msrp !== "0") && msrp}
                                price={price}
                            />
                        </div>
                   )}
                </Link>

                {children}
            </div>

        </div>
    );
}


export default SliderPod;