import Link from "next/link";
import Style from './NoReturns.module.scss';
import { IconNoReturns } from "components/icons/IconNoReturns";
import clsx from "clsx";
import utils from 'styles/globals/utils.module.scss';
// import ToolTip from "components/help/ToolTip";
import { triggerChat } from "thirdparty/Zendesk/Zendesk.service";


function ClickToChat(){
    return(
        <>
            Questions about the return policy for this item?&nbsp;
            <button type='button' onClick={() => triggerChat()} className={Style.chatBtn}>
                Chat with a member of our team
            </button>
        </>
    )
}


function NoReturns({ className }){

    return(
        <div className={clsx(Style.block, className)}>
            <IconNoReturns width="80" height="80" className={clsx(utils.me_1, Style.icon)} />

            <div className={clsx(utils.ms_1, utils.lh_sm)}>
                <p className={Style.heading}>
                    Return Policy
                </p>

                <small>
                    One or more components of this product are not eligible for return. &nbsp;
                
                    <Link href="/c/policies/" title="return policy" className={Style.cta}>
                        Learn more &rsaquo;
                    </Link> 
                </small>
            </div>
        </div>
    )
}

export default NoReturns;