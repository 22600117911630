
export function IconNoReturns(props){
    return(
        <svg xmlSpace="preserve" viewBox="0 0 45.118 48.742" {...props} aria-label="no returns">
            <g id="Group_1100" data-name="Group 1100" transform="translate(-1098.354 -750.969)">
                <path id="Line_51" data-name="Line 51" d="M11.935,12.935a1,1,0,0,1-.707-.293L-.707.707a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0L12.642,11.228a1,1,0,0,1-.707,1.707Z" transform="translate(1114.945 771.807)" fill="#2e303b"/>
                <path id="Line_52" data-name="Line 52" d="M0,12.935a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L11.228-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,12.642A1,1,0,0,1,0,12.935Z" transform="translate(1114.945 771.805)" fill="#2e303b"/>
                <g id="Group_1101" data-name="Group 1101">
                <path id="Path_412" data-name="Path 412" d="M1006.96,341.19a22.554,22.554,0,1,1,12.731-41.174,1,1,0,0,1-1.13,1.65,20.561,20.561,0,1,0,7.308,8.933,1,1,0,0,1,1.841-.783,22.56,22.56,0,0,1-20.749,31.374Z" transform="translate(802.282 1784.1) rotate(-90)" fill="#2e303b"/>
                <path id="Line_108" data-name="Line 108" d="M6,4a1,1,0,0,1-.446-.106l-6-3A1,1,0,0,1-.894-.447,1,1,0,0,1,.447-.894l6,3A1,1,0,0,1,6,4Z" transform="translate(1110.853 757.969)" fill="#2e303b"/>
                <path id="Line_109" data-name="Line 109" d="M0,7a1,1,0,0,1-.554-.168A1,1,0,0,1-.832,5.445l4-6A1,1,0,0,1,4.555-.832,1,1,0,0,1,4.832.555l-4,6A1,1,0,0,1,0,7Z" transform="translate(1110.853 751.969)" fill="#2e303b"/>
                </g>
            </g>      
        </svg>
    )
}