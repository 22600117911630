import PropTypes from 'prop-types';
import Loader from 'components/feedback/Loader';
import clsx from 'clsx';
import formatPrice from 'lib/Utils/formatPrice';

import Style from './ProductPrice.module.scss';

function ProductPrice({ 
    className, 
    isUpdating, 
    rrp, 
    isNegative, 
    price, 
    isPositive, 
    showWasPrice = true 
}){

    return(
        <span className={clsx(Style.block)}>
            { isUpdating ?
                <span className={Style.line}>
                    <Loader />
                </span>
            :
                <>
                    {(rrp && showWasPrice && rrp !== price ) ? (
                        <strike className={Style.rrp}>
                            {formatPrice(rrp)}
                        </strike>
                    ):null}

                    <span className={clsx(className, Style.value)}>
                        {isNegative && "- "} 
                        {isPositive && "+ "} 
                        {formatPrice(price)}
                    </span>
                </>
            }
        </span>
    );
}


ProductPrice.propTypes = {
    isUpdating: PropTypes.bool,
    price: PropTypes.oneOfType([ PropTypes.number, PropTypes.string]),
    rrp: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, PropTypes.bool]),
    isNegative: PropTypes.bool,
    isPositive: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    showWasPrice: PropTypes.bool
};


export default ProductPrice;